
// Vue
import {Options, Vue} from "vue-class-component";

// Models
import {AppOutgoingInvoiceIndex} from "@/models/app/outgoing-invoice";

// Components
import {
  ElDialog,
  ElButton,
  ElCollapse,
  ElCollapseItem,
} from "element-plus";
import {
  Right,
} from "@element-plus/icons-vue";

// Services
import {getDateFormat} from "@/services/app/date";
import {getInformation, getCurrencyFormatHRK, getCurrencyFormatEUR, getInvoiceStatus, getInvoiceStatusClass, getInvoiceNumberInYear} from "@/services/app/data";
import {getTranslation} from "@/services/app/translation";
import {AppInvoicePaymentIndex} from "@/models/app/invoice-payment";

@Options({
  props: [
    "outgoingInvoice",
  ],
  components: {
    ElDialog,
    ElButton,
    ElCollapse,
    ElCollapseItem,
    Right,
  },
})
export default class AppAdministratorInvoicesOutgoingInvoicesListFormLookupIndexVue extends Vue {
  isDialogVisible = false;

  outgoingInvoice: AppOutgoingInvoiceIndex = new AppOutgoingInvoiceIndex();

  get translation(): any {
    return getTranslation([
      "amount",
      "amountWithVAT",
      "compensation",
      "date",
      "dateOfClosing",
      "dateOfExchangeRate",
      "excerpt",
      "invoiceNumber",
      "invoiceNumberInYear",
      "invoicePayment",
      "paidAmount",
      "partner",
      "paymentDescription",
      "review",
      "reviewExcerpt",
      "status",
      "transaction",
      "year",
    ]);
  }

  getIsTransactionSet(data: AppInvoicePaymentIndex): boolean {
    return data.transaction_id !== null;
  }

  getIsInvoicePaymentCompensation(data: AppInvoicePaymentIndex): boolean {
    return data.is_compensation === true;
  }

  getDateFormat(data: string): string {
    return getDateFormat(data);
  }

  getInformation(data: string): string {
    return getInformation(data);
  }

  getCurrencyFormatHRK(data: string): string {
    return getCurrencyFormatHRK(data);
  }

  getCurrencyFormatEUR(data: string): string {
    return getCurrencyFormatEUR(data);
  }

  getInvoiceStatus(data: string): string {
    return getInvoiceStatus(data);
  }

  getInvoiceStatusClass(data: string): string {
    return getInvoiceStatusClass(data);
  }

  getInvoiceNumberInYear(number: string, year: string): string {
    return getInvoiceNumberInYear(number, year);
  }
}
