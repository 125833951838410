import { toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, createElementVNode as _createElementVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, withModifiers as _withModifiers, normalizeClass as _normalizeClass, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-249adaf3"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "app-administrator-invoices-outgoing-invoices-list-form-create-outgoing-invoice-payment" }
const _hoisted_2 = { class: "transactions" }
const _hoisted_3 = { class: "el-table-column__action" }
const _hoisted_4 = { class: "invoice-payment__headline" }
const _hoisted_5 = { class: "invoice-payment__headline-title" }
const _hoisted_6 = { class: "accounting-payroll" }
const _hoisted_7 = { class: "accounting-payroll__headline" }
const _hoisted_8 = { class: "accounting-payroll__information" }
const _hoisted_9 = { class: "accounting-payroll__information-label" }
const _hoisted_10 = { class: "accounting-payroll__information-value" }
const _hoisted_11 = { class: "accounting-payroll__information-value__currency" }
const _hoisted_12 = { class: "accounting-payroll__information" }
const _hoisted_13 = { class: "accounting-payroll__information-label" }
const _hoisted_14 = { class: "accounting-payroll__information-value" }
const _hoisted_15 = { class: "accounting-payroll__information-value__currency" }
const _hoisted_16 = { class: "accounting-payroll__information" }
const _hoisted_17 = { class: "accounting-payroll__information-label" }
const _hoisted_18 = { class: "accounting-payroll__information-value" }
const _hoisted_19 = { class: "accounting-payroll__information-value__currency" }
const _hoisted_20 = { class: "accounting-payroll" }
const _hoisted_21 = { class: "accounting-payroll__headline" }
const _hoisted_22 = { class: "accounting-payroll__information" }
const _hoisted_23 = { class: "accounting-payroll__information-label" }
const _hoisted_24 = { class: "accounting-payroll__information-value" }
const _hoisted_25 = { class: "accounting-payroll__information-value__currency" }
const _hoisted_26 = { class: "accounting-payroll__information-value__currency" }
const _hoisted_27 = { class: "accounting-payroll__information" }
const _hoisted_28 = { class: "accounting-payroll__information-label" }
const _hoisted_29 = { class: "accounting-payroll__information-value" }
const _hoisted_30 = { class: "accounting-payroll__information-value__currency" }
const _hoisted_31 = { class: "accounting-payroll__information-value__currency" }
const _hoisted_32 = { class: "accounting-payroll__information" }
const _hoisted_33 = { class: "accounting-payroll__information-label" }
const _hoisted_34 = { class: "accounting-payroll__information-value" }
const _hoisted_35 = { class: "accounting-payroll__information-value__currency" }
const _hoisted_36 = { class: "accounting-payroll__information-value__currency" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_el_table_column = _resolveComponent("el-table-column")!
  const _component_el_button = _resolveComponent("el-button")!
  const _component_el_table = _resolveComponent("el-table")!
  const _component_el_alert = _resolveComponent("el-alert")!
  const _component_el_form_item = _resolveComponent("el-form-item")!
  const _component_el_date_picker = _resolveComponent("el-date-picker")!
  const _component_el_input = _resolveComponent("el-input")!
  const _component_el_col = _resolveComponent("el-col")!
  const _component_el_row = _resolveComponent("el-row")!
  const _component_el_form = _resolveComponent("el-form")!
  const _component_el_dialog = _resolveComponent("el-dialog")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_el_dialog, {
      title: `${_ctx.translation.outgoingInvoice}: ${_ctx.getInvoiceNumberInYear(_ctx.outgoingInvoice.content.invoiceNumberInYear, _ctx.outgoingInvoice.content.year)} - ${_ctx.outgoingInvoice.content.invoiceNumber}`,
      class: _normalizeClass('el-dialog--invoice'),
      modelValue: _ctx.isDialogVisible,
      "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => ((_ctx.isDialogVisible) = $event))
    }, {
      default: _withCtx(() => [
        _createElementVNode("div", _hoisted_2, [
          _createVNode(_component_el_table, {
            data: _ctx.transactions,
            "max-height": "400",
            style: {"width":"100%"}
          }, {
            default: _withCtx(() => [
              _createVNode(_component_el_table_column, {
                label: _ctx.translation.bank,
                width: "90"
              }, {
                default: _withCtx((scope) => [
                  _createTextVNode(_toDisplayString(scope.row.excerpt.bank.content.title.substring(0, 10)) + "... ", 1)
                ]),
                _: 1
              }, 8, ["label"]),
              _createVNode(_component_el_table_column, {
                label: `${_ctx.translation.excerpt.substring(0,3)}.`,
                width: "40"
              }, {
                default: _withCtx((scope) => [
                  _createTextVNode(_toDisplayString(scope.row.excerpt.content.ordinalNumber), 1)
                ]),
                _: 1
              }, 8, ["label"]),
              _createVNode(_component_el_table_column, {
                label: `${_ctx.translation.transaction.substring(0,3)}.`,
                width: "40"
              }, {
                default: _withCtx((scope) => [
                  _createTextVNode(" #" + _toDisplayString(scope.row.content.ordinalNumber), 1)
                ]),
                _: 1
              }, 8, ["label"]),
              _createVNode(_component_el_table_column, {
                label: _ctx.translation.date,
                width: "80"
              }, {
                default: _withCtx((scope) => [
                  _createTextVNode(_toDisplayString(_ctx.getDateFormat(scope.row.excerpt.content.date)), 1)
                ]),
                _: 1
              }, 8, ["label"]),
              _createVNode(_component_el_table_column, {
                label: `${_ctx.translation.paymentType.substring(0,3)}.`,
                width: "40"
              }, {
                default: _withCtx((scope) => [
                  _createTextVNode(_toDisplayString(scope.row.payment_type.content.title), 1)
                ]),
                _: 1
              }, 8, ["label"]),
              _createVNode(_component_el_table_column, {
                label: _ctx.translation.paymentPurpose,
                "min-width": "360"
              }, {
                default: _withCtx((scope) => [
                  _createTextVNode(_toDisplayString(scope.row.content.paymentPurpose), 1)
                ]),
                _: 1
              }, 8, ["label"]),
              _createVNode(_component_el_table_column, {
                label: `${_ctx.translation.amount}`,
                width: "110"
              }, {
                default: _withCtx((scope) => [
                  _createTextVNode(_toDisplayString(_ctx.getCurrencyFormatHRK(scope.row.content.amount.hrk)), 1)
                ]),
                _: 1
              }, 8, ["label"]),
              _createVNode(_component_el_table_column, {
                label: `${_ctx.translation.amount}`,
                width: "110"
              }, {
                default: _withCtx((scope) => [
                  _createTextVNode(_toDisplayString(_ctx.getCurrencyFormatEUR(scope.row.content.amount.eur)), 1)
                ]),
                _: 1
              }, 8, ["label"]),
              _createVNode(_component_el_table_column, {
                label: _ctx.translation.action,
                align: "center",
                width: "110",
                fixed: "right"
              }, {
                default: _withCtx((scope) => [
                  _createElementVNode("div", _hoisted_3, [
                    _createVNode(_component_el_button, {
                      class: "el-button--primary el-button--extra-small el-button--block",
                      onClick: ($event: any) => (_ctx.setTransaction(scope.row))
                    }, {
                      default: _withCtx(() => [
                        _createTextVNode(_toDisplayString(_ctx.translation.choose), 1)
                      ]),
                      _: 2
                    }, 1032, ["onClick"])
                  ])
                ]),
                _: 1
              }, 8, ["label"])
            ]),
            _: 1
          }, 8, ["data"])
        ]),
        _createElementVNode("div", {
          class: _normalizeClass(["invoice-payment", {'d-none': !_ctx.isTransactionSet}])
        }, [
          _createElementVNode("div", _hoisted_4, [
            _createElementVNode("div", _hoisted_5, _toDisplayString(_ctx.translation.excerpt) + ": #" + _toDisplayString(_ctx.transaction.excerpt.content.ordinalNumber) + " - " + _toDisplayString(_ctx.translation.transaction) + ": #" + _toDisplayString(_ctx.transaction.content.ordinalNumber), 1)
          ]),
          _createVNode(_component_el_form, {
            class: "el-form--default",
            model: _ctx.formData,
            rules: _ctx.formDataRules,
            ref: "formComponent",
            onSubmit: _withModifiers(_ctx.validateForm, ["prevent"])
          }, {
            default: _withCtx(() => [
              (_ctx.isMaximumAmountExceeded)
                ? (_openBlock(), _createBlock(_component_el_alert, {
                    key: 0,
                    title: _ctx.translation.transactionHasBeenUsed,
                    type: "info",
                    closable: false
                  }, null, 8, ["title"]))
                : _createCommentVNode("", true),
              (_ctx.isOutgoingInvoiceClosed)
                ? (_openBlock(), _createBlock(_component_el_alert, {
                    key: 1,
                    title: _ctx.translation.outgoingInvoiceHasBeenClosed,
                    type: "info",
                    closable: false
                  }, null, 8, ["title"]))
                : _createCommentVNode("", true),
              _createVNode(_component_el_form_item, null, {
                default: _withCtx(() => [
                  _createElementVNode("div", _hoisted_6, [
                    _createElementVNode("div", _hoisted_7, [
                      _createElementVNode("strong", null, _toDisplayString(_ctx.translation.transaction), 1)
                    ]),
                    _createElementVNode("div", _hoisted_8, [
                      _createElementVNode("div", _hoisted_9, _toDisplayString(_ctx.translation.amount), 1),
                      _createElementVNode("div", _hoisted_10, [
                        _createElementVNode("span", _hoisted_11, _toDisplayString(_ctx.getCurrencyFormatEUR(_ctx.transaction.content.amount.eur)), 1)
                      ])
                    ]),
                    _createElementVNode("div", _hoisted_12, [
                      _createElementVNode("div", _hoisted_13, _toDisplayString(_ctx.translation.spent), 1),
                      _createElementVNode("div", _hoisted_14, [
                        _createElementVNode("span", _hoisted_15, _toDisplayString(_ctx.getCurrencyFormatEUR(_ctx.spentAmount)), 1)
                      ])
                    ]),
                    _createElementVNode("div", _hoisted_16, [
                      _createElementVNode("div", _hoisted_17, _toDisplayString(_ctx.translation.remaining), 1),
                      _createElementVNode("div", _hoisted_18, [
                        _createElementVNode("span", _hoisted_19, _toDisplayString(_ctx.getCurrencyFormatEUR(_ctx.remainingAmount)), 1)
                      ])
                    ])
                  ])
                ]),
                _: 1
              }),
              _createVNode(_component_el_form_item, null, {
                default: _withCtx(() => [
                  _createElementVNode("div", _hoisted_20, [
                    _createElementVNode("div", _hoisted_21, [
                      _createElementVNode("strong", null, _toDisplayString(_ctx.translation.outgoingInvoice), 1)
                    ]),
                    _createElementVNode("div", _hoisted_22, [
                      _createElementVNode("div", _hoisted_23, _toDisplayString(_ctx.translation.amount), 1),
                      _createElementVNode("div", _hoisted_24, [
                        _createElementVNode("span", _hoisted_25, _toDisplayString(_ctx.getCurrencyFormatHRK(_ctx.outgoingInvoice.content.amount.hrk)), 1),
                        _createElementVNode("span", _hoisted_26, _toDisplayString(_ctx.getCurrencyFormatEUR(_ctx.outgoingInvoice.content.amount.eur)), 1)
                      ])
                    ]),
                    _createElementVNode("div", _hoisted_27, [
                      _createElementVNode("div", _hoisted_28, _toDisplayString(_ctx.translation.paidAmount), 1),
                      _createElementVNode("div", _hoisted_29, [
                        _createElementVNode("span", _hoisted_30, _toDisplayString(_ctx.getCurrencyFormatHRK(_ctx.outgoingInvoice.content.paidAmount.hrk)), 1),
                        _createElementVNode("span", _hoisted_31, _toDisplayString(_ctx.getCurrencyFormatEUR(_ctx.outgoingInvoice.content.paidAmount.eur)), 1)
                      ])
                    ]),
                    _createElementVNode("div", _hoisted_32, [
                      _createElementVNode("div", _hoisted_33, _toDisplayString(_ctx.translation.difference), 1),
                      _createElementVNode("div", _hoisted_34, [
                        _createElementVNode("span", _hoisted_35, _toDisplayString(_ctx.getCurrencyFormatHRK(parseFloat(_ctx.outgoingInvoice.content.amount.hrk - _ctx.outgoingInvoice.content.paidAmount.hrk).toFixed(2))), 1),
                        _createElementVNode("span", _hoisted_36, _toDisplayString(_ctx.getCurrencyFormatEUR(parseFloat(_ctx.outgoingInvoice.content.amount.eur - _ctx.outgoingInvoice.content.paidAmount.eur).toFixed(2))), 1)
                      ])
                    ])
                  ])
                ]),
                _: 1
              }),
              _createVNode(_component_el_form_item, {
                label: _ctx.translation.date,
                prop: "content.date"
              }, {
                default: _withCtx(() => [
                  _createVNode(_component_el_date_picker, {
                    modelValue: _ctx.formData.content.date,
                    "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.formData.content.date) = $event)),
                    placeholder: _ctx.translation.titlePlaceholderDate,
                    format: "DD.MM.YYYY."
                  }, null, 8, ["modelValue", "placeholder"])
                ]),
                _: 1
              }, 8, ["label"]),
              _createVNode(_component_el_row, {
                gutter: 16,
                type: "flex"
              }, {
                default: _withCtx(() => [
                  _createVNode(_component_el_col, {
                    xs: 24,
                    sm: 12,
                    md: 12,
                    lg: 12,
                    xl: 12
                  }, {
                    default: _withCtx(() => [
                      _createVNode(_component_el_form_item, {
                        label: `${_ctx.translation.amount} (HRK)`,
                        prop: "content.amount.hrk"
                      }, {
                        default: _withCtx(() => [
                          _createVNode(_component_el_input, {
                            modelValue: _ctx.formData.content.amount.hrk,
                            "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.formData.content.amount.hrk) = $event)),
                            placeholder: _ctx.translation.titlePlaceholderAmount,
                            disabled: ""
                          }, null, 8, ["modelValue", "placeholder"])
                        ]),
                        _: 1
                      }, 8, ["label"])
                    ]),
                    _: 1
                  }),
                  _createVNode(_component_el_col, {
                    xs: 24,
                    sm: 12,
                    md: 12,
                    lg: 12,
                    xl: 12
                  }, {
                    default: _withCtx(() => [
                      _createVNode(_component_el_form_item, {
                        label: `${_ctx.translation.amount} (EUR)`,
                        prop: "content.amount.eur"
                      }, {
                        default: _withCtx(() => [
                          _createVNode(_component_el_input, {
                            type: "number",
                            step: "0.01",
                            max: _ctx.maximumAmount,
                            modelValue: _ctx.formData.content.amount.eur,
                            "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.formData.content.amount.eur) = $event)),
                            placeholder: _ctx.translation.titlePlaceholderAmount
                          }, null, 8, ["max", "modelValue", "placeholder"])
                        ]),
                        _: 1
                      }, 8, ["label"])
                    ]),
                    _: 1
                  })
                ]),
                _: 1
              }),
              _createVNode(_component_el_form_item, null, {
                default: _withCtx(() => [
                  _createVNode(_component_el_button, {
                    type: "primary",
                    class: "el-button--primary el-button--block",
                    "native-type": "submit",
                    disabled: _ctx.isOutgoingInvoiceClosed || _ctx.isMaximumAmountExceeded || _ctx.isRemainingLessThanInvoiceAmount,
                    loading: _ctx.isLoading
                  }, {
                    default: _withCtx(() => [
                      _createTextVNode(_toDisplayString(_ctx.translation.pay), 1)
                    ]),
                    _: 1
                  }, 8, ["disabled", "loading"])
                ]),
                _: 1
              })
            ]),
            _: 1
          }, 8, ["model", "rules", "onSubmit"])
        ], 2)
      ]),
      _: 1
    }, 8, ["title", "modelValue"])
  ]))
}