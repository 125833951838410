
// Vue
import {Options, Vue} from "vue-class-component";

// Components
import {
  ElRow,
  ElCol,
} from "element-plus";
import AdministratorNavigationVue from "@/components/administrator/navigation.vue";
import AdministratorNavigationInvoicesVue from "@/components/administrator/navigation-invoices.vue";
import AppAdministratorInvoicesOutgoingInvoicesListIndexVue from "@/views/app/administrator/invoices/outgoing-invoices/list/index.vue";

@Options({
  components: {
    ElRow,
    ElCol,
    AdministratorNavigationVue,
    AdministratorNavigationInvoicesVue,
    AppAdministratorInvoicesOutgoingInvoicesListIndexVue,
  },
})
export default class AppAdministratorInvoicesOutgoingInvoicesIndexVue extends Vue {}
