import { resolveComponent as _resolveComponent, createVNode as _createVNode, withModifiers as _withModifiers, createElementVNode as _createElementVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, withCtx as _withCtx, renderList as _renderList, Fragment as _Fragment, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, normalizeClass as _normalizeClass, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-a0dd4ea6"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "app-administrator-invoices-outgoing-invoices-list-form-create" }
const _hoisted_2 = { class: "app-administrator-invoices-outgoing-invoices-list-form-create__action" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Plus = _resolveComponent("Plus")!
  const _component_el_alert = _resolveComponent("el-alert")!
  const _component_el_input = _resolveComponent("el-input")!
  const _component_el_form_item = _resolveComponent("el-form-item")!
  const _component_el_col = _resolveComponent("el-col")!
  const _component_el_row = _resolveComponent("el-row")!
  const _component_el_option = _resolveComponent("el-option")!
  const _component_el_select = _resolveComponent("el-select")!
  const _component_el_date_picker = _resolveComponent("el-date-picker")!
  const _component_el_button = _resolveComponent("el-button")!
  const _component_el_form = _resolveComponent("el-form")!
  const _component_el_dialog = _resolveComponent("el-dialog")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("a", {
        href: "#",
        onClick: _cache[0] || (_cache[0] = _withModifiers(($event: any) => (_ctx.isDialogVisible = true), ["prevent"]))
      }, [
        _createVNode(_component_Plus)
      ])
    ]),
    _createVNode(_component_el_dialog, {
      title: _ctx.translation.add,
      class: _normalizeClass('el-dialog--invoice'),
      modelValue: _ctx.isDialogVisible,
      "onUpdate:modelValue": _cache[10] || (_cache[10] = ($event: any) => ((_ctx.isDialogVisible) = $event))
    }, {
      default: _withCtx(() => [
        _createVNode(_component_el_form, {
          class: "el-form--default",
          model: _ctx.formData,
          rules: _ctx.formDataRules,
          ref: "formComponent",
          onSubmit: _withModifiers(_ctx.validateForm, ["prevent"])
        }, {
          default: _withCtx(() => [
            (_ctx.isDisabled)
              ? (_openBlock(), _createBlock(_component_el_alert, {
                  key: 0,
                  title: "Već postoji račun. Provjerite broj računa u godini, godinu, broj računa ili partnera.",
                  type: "error",
                  effect: "dark",
                  closable: false
                }))
              : _createCommentVNode("", true),
            _createVNode(_component_el_row, {
              gutter: 16,
              type: "flex"
            }, {
              default: _withCtx(() => [
                _createVNode(_component_el_col, {
                  xs: 24,
                  sm: 24,
                  md: 8,
                  lg: 8,
                  xl: 8
                }, {
                  default: _withCtx(() => [
                    _createVNode(_component_el_form_item, {
                      label: _ctx.translation.invoiceNumberInYear,
                      prop: "content.invoiceNumberInYear"
                    }, {
                      default: _withCtx(() => [
                        _createVNode(_component_el_input, {
                          modelValue: _ctx.formData.content.invoiceNumberInYear,
                          "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.formData.content.invoiceNumberInYear) = $event)),
                          placeholder: _ctx.translation.titlePlaceholderInvoiceNumberInYear,
                          onKeyup: _withModifiers(_ctx.getOutgoingInvoiceStatus, ["prevent"])
                        }, null, 8, ["modelValue", "placeholder", "onKeyup"])
                      ]),
                      _: 1
                    }, 8, ["label"])
                  ]),
                  _: 1
                }),
                _createVNode(_component_el_col, {
                  xs: 24,
                  sm: 24,
                  md: 8,
                  lg: 8,
                  xl: 8
                }, {
                  default: _withCtx(() => [
                    _createVNode(_component_el_form_item, {
                      label: _ctx.translation.year,
                      prop: "content.year"
                    }, {
                      default: _withCtx(() => [
                        _createVNode(_component_el_input, {
                          modelValue: _ctx.formData.content.year,
                          "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.formData.content.year) = $event)),
                          placeholder: _ctx.translation.titlePlaceholderYear,
                          onKeyup: _withModifiers(_ctx.getOutgoingInvoiceStatus, ["prevent"])
                        }, null, 8, ["modelValue", "placeholder", "onKeyup"])
                      ]),
                      _: 1
                    }, 8, ["label"])
                  ]),
                  _: 1
                }),
                _createVNode(_component_el_col, {
                  xs: 24,
                  sm: 24,
                  md: 8,
                  lg: 8,
                  xl: 8
                }, {
                  default: _withCtx(() => [
                    _createVNode(_component_el_form_item, {
                      label: _ctx.translation.invoiceNumber,
                      prop: "content.invoiceNumber"
                    }, {
                      default: _withCtx(() => [
                        _createVNode(_component_el_input, {
                          modelValue: _ctx.formData.content.invoiceNumber,
                          "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => ((_ctx.formData.content.invoiceNumber) = $event)),
                          placeholder: _ctx.translation.titlePlaceholderInvoiceNumber,
                          onKeyup: _withModifiers(_ctx.getOutgoingInvoiceStatus, ["prevent"])
                        }, null, 8, ["modelValue", "placeholder", "onKeyup"])
                      ]),
                      _: 1
                    }, 8, ["label"])
                  ]),
                  _: 1
                })
              ]),
              _: 1
            }),
            _createVNode(_component_el_form_item, {
              label: _ctx.translation.partner,
              prop: "partner_id"
            }, {
              default: _withCtx(() => [
                _createVNode(_component_el_select, {
                  modelValue: _ctx.formData.partner_id,
                  "onUpdate:modelValue": _cache[4] || (_cache[4] = ($event: any) => ((_ctx.formData.partner_id) = $event)),
                  placeholder: _ctx.translation.choose,
                  filterable: "",
                  onChange: _ctx.getOutgoingInvoiceStatus
                }, {
                  default: _withCtx(() => [
                    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.partners, (partner) => {
                      return (_openBlock(), _createBlock(_component_el_option, {
                        key: partner.id,
                        label: partner.content.title,
                        value: partner.id
                      }, null, 8, ["label", "value"]))
                    }), 128))
                  ]),
                  _: 1
                }, 8, ["modelValue", "placeholder", "onChange"])
              ]),
              _: 1
            }, 8, ["label"]),
            _createVNode(_component_el_form_item, {
              label: _ctx.translation.paymentDescription,
              prop: "content.paymentDescription"
            }, {
              default: _withCtx(() => [
                _createVNode(_component_el_input, {
                  modelValue: _ctx.formData.content.paymentDescription,
                  "onUpdate:modelValue": _cache[5] || (_cache[5] = ($event: any) => ((_ctx.formData.content.paymentDescription) = $event)),
                  placeholder: _ctx.translation.titlePlaceholderPaymentDescription
                }, null, 8, ["modelValue", "placeholder"])
              ]),
              _: 1
            }, 8, ["label"]),
            _createVNode(_component_el_row, {
              gutter: 16,
              type: "flex"
            }, {
              default: _withCtx(() => [
                _createVNode(_component_el_col, {
                  xs: 24,
                  sm: 12,
                  md: 12,
                  lg: 12,
                  xl: 12
                }, {
                  default: _withCtx(() => [
                    _createVNode(_component_el_form_item, {
                      label: `${_ctx.translation.amountWithVAT} (HRK)`,
                      prop: "content.amount.hrk"
                    }, {
                      default: _withCtx(() => [
                        _createVNode(_component_el_input, {
                          modelValue: _ctx.formData.content.amount.hrk,
                          "onUpdate:modelValue": _cache[6] || (_cache[6] = ($event: any) => ((_ctx.formData.content.amount.hrk) = $event)),
                          placeholder: _ctx.translation.titlePlaceholderAmountWithVAT,
                          disabled: ""
                        }, null, 8, ["modelValue", "placeholder"])
                      ]),
                      _: 1
                    }, 8, ["label"])
                  ]),
                  _: 1
                }),
                _createVNode(_component_el_col, {
                  xs: 24,
                  sm: 12,
                  md: 12,
                  lg: 12,
                  xl: 12
                }, {
                  default: _withCtx(() => [
                    _createVNode(_component_el_form_item, {
                      label: `${_ctx.translation.amountWithVAT} (EUR)`,
                      prop: "content.amount.eur"
                    }, {
                      default: _withCtx(() => [
                        _createVNode(_component_el_input, {
                          type: "number",
                          step: "0.01",
                          modelValue: _ctx.formData.content.amount.eur,
                          "onUpdate:modelValue": _cache[7] || (_cache[7] = ($event: any) => ((_ctx.formData.content.amount.eur) = $event)),
                          placeholder: _ctx.translation.titlePlaceholderAmountWithVAT
                        }, null, 8, ["modelValue", "placeholder"])
                      ]),
                      _: 1
                    }, 8, ["label"])
                  ]),
                  _: 1
                })
              ]),
              _: 1
            }),
            _createVNode(_component_el_row, {
              gutter: 16,
              type: "flex"
            }, {
              default: _withCtx(() => [
                _createVNode(_component_el_col, {
                  xs: 24,
                  sm: 12,
                  md: 12,
                  lg: 12,
                  xl: 12
                }, {
                  default: _withCtx(() => [
                    _createVNode(_component_el_form_item, {
                      label: _ctx.translation.date,
                      prop: "content.date"
                    }, {
                      default: _withCtx(() => [
                        _createVNode(_component_el_date_picker, {
                          modelValue: _ctx.formData.content.date,
                          "onUpdate:modelValue": _cache[8] || (_cache[8] = ($event: any) => ((_ctx.formData.content.date) = $event)),
                          placeholder: _ctx.translation.titlePlaceholderDate,
                          format: "DD.MM.YYYY."
                        }, null, 8, ["modelValue", "placeholder"])
                      ]),
                      _: 1
                    }, 8, ["label"])
                  ]),
                  _: 1
                }),
                _createVNode(_component_el_col, {
                  xs: 24,
                  sm: 12,
                  md: 12,
                  lg: 12,
                  xl: 12
                }, {
                  default: _withCtx(() => [
                    _createVNode(_component_el_form_item, {
                      label: _ctx.translation.dateOfExchangeRate,
                      prop: "content.dateOfExchangeRate"
                    }, {
                      default: _withCtx(() => [
                        _createVNode(_component_el_date_picker, {
                          modelValue: _ctx.formData.content.dateOfExchangeRate,
                          "onUpdate:modelValue": _cache[9] || (_cache[9] = ($event: any) => ((_ctx.formData.content.dateOfExchangeRate) = $event)),
                          placeholder: _ctx.translation.titlePlaceholderDateOfExchangeRate,
                          format: "DD.MM.YYYY."
                        }, null, 8, ["modelValue", "placeholder"])
                      ]),
                      _: 1
                    }, 8, ["label"])
                  ]),
                  _: 1
                })
              ]),
              _: 1
            }),
            _createVNode(_component_el_form_item, null, {
              default: _withCtx(() => [
                _createVNode(_component_el_button, {
                  type: "primary",
                  class: "el-button--primary el-button--block",
                  "native-type": "submit",
                  loading: _ctx.isLoading,
                  disabled: _ctx.isDisabled
                }, {
                  default: _withCtx(() => [
                    _createTextVNode(_toDisplayString(_ctx.translation.add), 1)
                  ]),
                  _: 1
                }, 8, ["loading", "disabled"])
              ]),
              _: 1
            })
          ]),
          _: 1
        }, 8, ["model", "rules", "onSubmit"])
      ]),
      _: 1
    }, 8, ["title", "modelValue"])
  ]))
}