
// Vue
import {Options, Vue} from "vue-class-component";

// Models
import {AppTransactionIndex} from "@/models/app/transaction";
import {AppOutgoingInvoiceIndex} from "@/models/app/outgoing-invoice";
import {AppInvoicePaymentCreateOutgoingInvoicePaymentIndex} from "@/models/app/invoice-payment/create-outgoing-invoice-payment";
import {ResponseIndex} from "@/models/response";
import {PaginationIndex} from "@/models/pagination";

// Components
import {
  ElDialog,
  ElRow,
  ElCol,
  ElTable,
  ElTableColumn,
  ElAlert,
  ElForm,
  ElFormItem,
  ElInput,
  ElDatePicker,
  ElButton,
} from "element-plus";

// Services
import {postRequest} from "@/services/api/request";
import {getDateFormat} from "@/services/app/date";
import {getCurrencyFormatHRK, getCurrencyFormatEUR, getInvoiceNumberInYear} from "@/services/app/data";
import {getTranslation} from "@/services/app/translation";
import {AppInvoicePaymentIndex} from "@/models/app/invoice-payment";


@Options({
  props: [
    "transactions",
    "outgoingInvoice",
    "searchQuery",
    "partnerId",
    "status",
    "year",
    "pagination",
  ],
  components: {
    ElDialog,
    ElRow,
    ElCol,
    ElTable,
    ElTableColumn,
    ElAlert,
    ElForm,
    ElFormItem,
    ElInput,
    ElDatePicker,
    ElButton,
  },
  watch: {
    'formData.outgoing_invoice_id'(): void {
      if (this.formData.outgoing_invoice_id !== "") {
        if (this.outgoingInvoice !== undefined) {
          this.formData.content.amount.eur = Number(parseFloat(this.outgoingInvoice.content.amount.eur) - parseFloat(this.outgoingInvoice.content.paidAmount.eur)).toFixed(2);
        }
      }
    },
    'formData.content.amount.eur'(): void {
      this.formData.content.amount.hrk = Number((this.formData.content.amount.eur) * 7.53450).toFixed(2);
    },
  },
})
export default class AppAdministratorInvoicesOutgoingInvoicesListFormCreateOutgoingInvoicePaymentIndexVue extends Vue {
  isLoading = false;
  isDialogVisible = false;

  transaction: AppTransactionIndex = new AppTransactionIndex();
  transactions: Array<AppTransactionIndex> = [];
  outgoingInvoice: AppOutgoingInvoiceIndex = new AppOutgoingInvoiceIndex();

  searchQuery = "*";
  partnerId = "*";
  status = "*";
  year = new Date().getFullYear();
  pagination = new PaginationIndex();

  formData: AppInvoicePaymentCreateOutgoingInvoicePaymentIndex = new AppInvoicePaymentCreateOutgoingInvoicePaymentIndex();
  formDataRules = {
    outgoing_invoice_id: [
      {
        required: true,
        trigger: "blur",
      },
    ],
    content: {
      date: [
        {
          required: true,
          trigger: "blur",
        },
      ],
      amount: {
        hrk: [
          {
            required: true,
            trigger: "blur",
          },
        ],
        eur: [
          {
            required: true,
            trigger: "blur",
          },
        ],
      },
    },
  };

  get translation(): any {
    return getTranslation([
      "action",
      "bank",
      "amount",
      "change",
      "choose",
      "compensation",
      "date",
      "difference",
      "excerpt",
      "information",
      "outgoingInvoice",
      "outgoingInvoiceHasBeenClosed",
      "invoicePayment",
      "invoicePayments",
      "noData",
      "transaction",
      "transactions",
      "transactionHasBeenUsed",
      "titlePlaceholderAmount",
      "titlePlaceholderDate",
      "paidAmount",
      "partner",
      "pay",
      "paymentPurpose",
      "paymentType",
      "remaining",
      "review",
      "spent",
      "undefined",
    ]);
  }

  get isTransactionSet(): boolean {
    return this.transaction.id !== "";
  }

  get isOutgoingInvoiceClosed(): boolean {
    return this.outgoingInvoice.content.status === "1";
  }

  get isMaximumAmountExceeded(): boolean {
    return this.maximumAmount === 0;
  }

  get isRemainingLessThanInvoiceAmount(): boolean {
    if (this.formData.outgoing_invoice_id === "") {
      return false;
    }

    return Number(this.remainingAmount) < Number(this.formData.content.amount.eur);
  }

  get maximumAmount(): number {
    let invoicePaymentsAmount = 0;
    let transactionAmount = parseFloat(this.transaction.content.amount.eur);

    switch (this.transaction.payment_type.content.title) {
      case "D":
        this.transaction.invoice_payments.forEach((invoicePayment: AppInvoicePaymentIndex) => {
          if (invoicePayment.incoming_invoice_id) {
            invoicePaymentsAmount = invoicePaymentsAmount + parseFloat(invoicePayment.content.amount.eur);
          }
        });
        break;
      case "P":
        this.transaction.invoice_payments.forEach((invoicePayment: AppInvoicePaymentIndex) => {
          if (invoicePayment.outgoing_invoice_id) {
            invoicePaymentsAmount = invoicePaymentsAmount + parseFloat(invoicePayment.content.amount.eur);
          }
        });
        break;
    }

    return this.getDifferenceAmount(transactionAmount, invoicePaymentsAmount);
  }

  get remainingAmount(): string {
    return this.maximumAmount.toFixed(2);
  }

  get spentAmount(): string {
    return (parseFloat(this.transaction.content.amount.eur) - this.maximumAmount).toFixed(2);
  }

  getDifferenceAmount(transactionAmount: number, invoicePaymentsAmount: number): number {
    const differenceAmount = Math.round((transactionAmount - invoicePaymentsAmount) * 100) / 100;

    if (differenceAmount.toFixed(2) === "-0.01") {
      return 0;
    }

    if (differenceAmount.toFixed(2) === "-0.00") {
      return 0;
    }

    return differenceAmount;
  }

  getDateFormat(data: string): string {
    return getDateFormat(data);
  }

  getCurrencyFormatHRK(data: string): string {
    return getCurrencyFormatHRK(data);
  }

  getCurrencyFormatEUR(data: string): string {
    return getCurrencyFormatEUR(data);
  }

  getInvoiceNumberInYear(number: string, year: string): string {
    return getInvoiceNumberInYear(number, year);
  }

  $refs!: {
    formComponent: HTMLFormElement;
  };

  validateForm(): void {
    this.$refs.formComponent.validate((response: any) => {
      if (response) {
        this.submitForm();
      }
    });
  }

  async submitForm(): Promise<void> {
    this.isLoading = true;
    await postRequest({
      uri: "/common/invoice-payment/create-outgoing-invoice-payment",
      formData: this.formData,
      isProtected: true,
      isSuccessNotificationVisible: true,
      isErrorNotificationVisible: true,
    }).then((r: ResponseIndex) => {
      if (r.status === "success") {
        this.$emit('getOutgoingInvoices', this.searchQuery, this.partnerId, this.status, this.year, this.pagination.current_page);
        this.isDialogVisible = false;
        this.resetFormData();
        this.resetTransaction();
      }
    });
    this.isLoading = false;
  }

  setTransaction(data: AppTransactionIndex) {
    this.transaction = data;
    this.setFormData();
  }

  setFormData(): void {
    this.formData.outgoing_invoice_id = this.outgoingInvoice.id;
    this.formData.payment_type_id = this.transaction.payment_type_id;
    this.formData.transaction_id = this.transaction.id;
    this.formData.content.date = this.transaction.excerpt.content.date;
  }

  resetTransaction(): void {
    this.transaction = new AppTransactionIndex();
  }

  resetFormData(): void {
    this.formData = new AppInvoicePaymentCreateOutgoingInvoicePaymentIndex();
  }
}
