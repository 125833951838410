
// Vue
import {Options, Vue} from "vue-class-component";

// Store
import store from "@/store/index";

// Models
import {AppOutgoingInvoiceIndex} from "@/models/app/outgoing-invoice";
import {AppPartnerIndex} from "@/models/app/partner";
import {AppTransactionIndex} from "@/models/app/transaction";
import {ResponseIndex} from "@/models/response";
import {PaginationIndex} from "@/models/pagination";

// Components
import {
  ElTable,
  ElTableColumn,
  ElButton,
  ElPagination,
} from "element-plus";
import {Search, Edit, Delete} from "@element-plus/icons-vue";
import AppAdministratorInvoicesOutgoingInvoicesListFormFilterIndexVue from "@/views/app/administrator/invoices/outgoing-invoices/list/form/filter.vue";
import AppAdministratorInvoicesOutgoingInvoicesListFormLookupIndexVue from "@/views/app/administrator/invoices/outgoing-invoices/list/form/lookup.vue";
import AppAdministratorInvoicesOutgoingInvoicesListFormCreateIndexVue from "@/views/app/administrator/invoices/outgoing-invoices/list/form/create.vue";
import AppAdministratorInvoicesOutgoingInvoicesListFormUpdateIndexVue from "@/views/app/administrator/invoices/outgoing-invoices/list/form/update.vue";
import AppAdministratorInvoicesOutgoingInvoicesListFormRemoveIndexVue from "@/views/app/administrator/invoices/outgoing-invoices/list/form/remove.vue";
import AppAdministratorInvoicesOutgoingInvoicesListFormCreateIncomingInvoicePaymentIndexVue from "@/views/app/administrator/invoices/outgoing-invoices/list/form/create-outgoing-invoice-payment.vue";
import LoadingIndexVue from "@/components/loading.vue";

// Services
import {getRequest} from "@/services/api/request";
import {getDateFormat} from "@/services/app/date";
import {
  getInformation,
  getCurrencyFormatHRK,
  getCurrencyFormatEUR,
  getInvoiceStatusShort,
  getInvoiceStatusClass,
  getInvoiceNumberInYear,
} from "@/services/app/data";
import {getTranslation} from "@/services/app/translation";

@Options({
  components: {
    ElTable,
    ElTableColumn,
    ElButton,
    ElPagination,
    Search,
    Edit,
    Delete,
    AppAdministratorInvoicesOutgoingInvoicesListFormFilterIndexVue,
    AppAdministratorInvoicesOutgoingInvoicesListFormLookupIndexVue,
    AppAdministratorInvoicesOutgoingInvoicesListFormCreateIndexVue,
    AppAdministratorInvoicesOutgoingInvoicesListFormUpdateIndexVue,
    AppAdministratorInvoicesOutgoingInvoicesListFormRemoveIndexVue,
    AppAdministratorInvoicesOutgoingInvoicesListFormCreateIncomingInvoicePaymentIndexVue,
    LoadingIndexVue,
  },
})
export default class AppAdministratorInvoicesOutgoingInvoicesListIndexVue extends Vue {
  isLoading = false;

  searchQuery = "*";
  partner_id = "*";
  status = "*";
  year = new Date().getFullYear();
  pagination: PaginationIndex = new PaginationIndex();

  outgoingInvoice: AppOutgoingInvoiceIndex = new AppOutgoingInvoiceIndex();
  outgoingInvoices: Array<AppOutgoingInvoiceIndex> = [];
  partners: Array<AppPartnerIndex> = [];
  transactions: Array<AppTransactionIndex> = [];

  $refs!: {
    AppAdministratorInvoicesOutgoingInvoicesListFormLookupIndexVue: any;
    AppAdministratorInvoicesOutgoingInvoicesListFormUpdateIndexVue: any;
    AppAdministratorInvoicesOutgoingInvoicesListFormRemoveIndexVue: any;
    AppAdministratorInvoicesOutgoingInvoicesListFormCreateIncomingInvoicePaymentIndexVue: any;
  };

  get isDataLoaded(): boolean {
    return this.outgoingInvoices.length > 0 && this.isLoading === false;
  }

  get isDataLoadedAndEmpty(): boolean {
    return this.outgoingInvoices.length === 0 && this.isLoading === false;
  }

  get isPaginationVisible(): boolean {
    return this.pagination.last_page > 1 && this.isLoading === false;
  }

  getDateFormat(data: string): string {
    return getDateFormat(data);
  }

  getInformation(data: string): string {
    return getInformation(data);
  }

  getCurrencyFormatHRK(data: string): string {
    return getCurrencyFormatHRK(data);
  }

  getCurrencyFormatEUR(data: string): string {
    return getCurrencyFormatEUR(data);
  }

  getInvoiceStatusShort(data: string): string {
    return getInvoiceStatusShort(data);
  }

  getInvoiceStatusClass(data: string): string {
    return getInvoiceStatusClass(data);
  }

  getInvoiceNumberInYear(number: string, year: string): string {
    return getInvoiceNumberInYear(number, year);
  }

  get translation(): any {
    return getTranslation([
      "amountWithVAT",
      "date",
      "dateOfExchangeRateShort",
      "edit",
      "invoiceNumber",
      "invoiceNumberInYear",
      "invoiceNumberInYearIRA",
      "noData",
      "outgoingInvoices",
      "paid",
      "partner",
      "paymentDescription",
      "payments",
      "remove",
      "review",
      "status",
    ]);
  }

  async getOutgoingInvoices(searchQuery = "*", partner_id = "*", status = "*", year = new Date().getFullYear(), page = 1): Promise<void> {
    this.isLoading = true;
    await getRequest({
      uri: `/common/outgoing-invoices`,
      formData: {
        organization_id: store.getters.getOrganizationMember.organization_id,
        searchQuery: this.searchQuery = searchQuery,
        partner_id: this.partner_id = partner_id,
        status: this.status = status,
        year: this.year = year,
        page: this.pagination.current_page = page,
        pagination: true,
      },
      isProtected: true,
    }).then((r: ResponseIndex) => {
      if (r.data) {
        this.outgoingInvoices = r.data.data;
        this.pagination = {
          current_page: r.data.current_page,
          first_page_url: r.data.first_page_url,
          from: r.data.from,
          last_page: r.data.last_page,
          last_page_url: r.data.last_page_url,
          next_page_url: r.data.next_page_url,
          path: r.data.path,
          per_page: r.data.per_page,
          prev_page_url: r.data.prev_page_url,
          to: r.data.to,
          total: r.data.total,
        };
      }
    });
    this.isLoading = false;
  }

  async getPartners(): Promise<void> {
    await getRequest({
      uri: `/common/partners`,
      formData: {
        organization_id: store.getters.getOrganizationMember.organization_id,
        pagination: false,
      },
      isProtected: true,
    }).then((r: ResponseIndex) => {
      if (r.data) {
        this.partners = r.data;
      }
    });
  }

  async getTransactionsUnallocatedFunds(partnerId: string, paymentTypeId: string, outgoingInvoice: AppOutgoingInvoiceIndex): Promise<void> {
    await getRequest({
      uri: `/common/transactions/by-partner/unallocated-funds`,
      formData: {
        organization_id: store.getters.getOrganizationMember.organization_id,
        partner_id: partnerId,
        payment_type_id: paymentTypeId,
      },
      isProtected: true,
    }).then((r: ResponseIndex) => {
      if (r.data) {
        this.transactions = r.data;
        this.outgoingInvoice = outgoingInvoice;

        if (this.transactions.length > 0) {
          this.setDialogOutgoingInvoiceCreateIncomingInvoicePayment();
        }
      }
    });
  }

  setPagination(page): void {
    this.getOutgoingInvoices(this.searchQuery, this.partner_id, this.status, this.year, page);
  }

  setDialogOutgoingInvoiceLookup(data: AppOutgoingInvoiceIndex) {
    this.outgoingInvoice = data;
    this.$refs.AppAdministratorInvoicesOutgoingInvoicesListFormLookupIndexVue.isDialogVisible = true;
  }

  setDialogOutgoingInvoiceUpdate(data: AppOutgoingInvoiceIndex) {
    this.outgoingInvoice = data;
    this.$refs.AppAdministratorInvoicesOutgoingInvoicesListFormUpdateIndexVue.isDialogVisible = true;
  }

  setDialogOutgoingInvoiceRemove(data: AppOutgoingInvoiceIndex) {
    this.outgoingInvoice = data;
    this.$refs.AppAdministratorInvoicesOutgoingInvoicesListFormRemoveIndexVue.isDialogVisible = true;
  }

  setDialogOutgoingInvoiceCreateIncomingInvoicePayment() {
    this.$refs.AppAdministratorInvoicesOutgoingInvoicesListFormCreateIncomingInvoicePaymentIndexVue.isDialogVisible = true;
  }

  async created(): Promise<void> {
    await this.getOutgoingInvoices();
    await this.getPartners();
  }
}
