// Store
import store from "@/store";

// Services
import {getYear} from "@/services/app/date";

export interface AppOutgoingInvoiceCreateIndex {
  organization_id: string | string[];
  partner_id: string | string[];
  content: {
    year: string;
    invoiceNumber: string;
    invoiceNumberInYear: string;
    paymentDescription: string;
    amount: {
      hrk: string,
      eur: string,
    };
    paidAmount: {
      hrk: string,
      eur: string,
    };
    status: string;
    date: string;
    dateOfExchangeRate: string;
    dateOfClosing: string;
  }
}

export class AppOutgoingInvoiceCreateIndex implements AppOutgoingInvoiceCreateIndex {
  organization_id: string | string[];
  partner_id: string | string[];
  content: {
    year: string;
    invoiceNumber: string;
    invoiceNumberInYear: string;
    paymentDescription: string;
    amount: {
      hrk: string,
      eur: string,
    };
    paidAmount: {
      hrk: string,
      eur: string,
    };
    status: string;
    date: string;
    dateOfExchangeRate: string;
    dateOfClosing: string;
  }

  constructor() {
    this.organization_id = store.getters.getOrganizationMember.organization_id ?? "";
    this.partner_id = "";
    this.content = {
      year: getYear(),
      invoiceNumber: "",
      invoiceNumberInYear: "",
      paymentDescription: "",
      amount: {
        hrk: "",
        eur: "",
      },
      paidAmount: {
        hrk: "0",
        eur: "0",
      },
      status: "0",
      date: "",
      dateOfExchangeRate: "",
      dateOfClosing: "",
    };
  }
}