
// Vue
import {Options, Vue} from "vue-class-component";

// Services
import {getTranslation} from "@/services/app/translation";

@Options({})
export default class AdministratorNavigationInvoicesVue extends Vue {
  exactRoute = "";

  get translation(): any {
    return getTranslation([
      "compensations",
      "excerpts",
      "incomingInvoices",
      "invoicePayments",
      "outgoingInvoices",
    ]);
  }

  setExactRoute(): void {
    switch (this.$route.name) {
      case "AppAdministratorInvoicesOutgoingInvoicesIndexVue":
        this.exactRoute = "outgoing-invoices";
        break;
      case "AppAdministratorInvoicesIncomingInvoicesIndexVue":
        this.exactRoute = "incoming-invoices";
        break;
      case "AppAdministratorInvoicesExcerptsRootIndexVue":
      case "AppAdministratorInvoicesExcerptsDetailsIndexVue":
        this.exactRoute = "excerpts";
        break;
      case "AppAdministratorInvoicesInvoicePaymentsIndexVue":
        this.exactRoute = "invoice-payments";
        break;
      case "AppAdministratorInvoiceCompensationsIndexVue":
        this.exactRoute = "compensations";
        break;
    }
  }

  created(): void {
    this.setExactRoute();
  }
}
