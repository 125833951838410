import { toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, normalizeClass as _normalizeClass, withCtx as _withCtx, createVNode as _createVNode, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-5ad9ab10"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "navigation-secondary" }
const _hoisted_2 = { class: "navigation-secondary__list" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_router_link = _resolveComponent("router-link")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("ul", null, [
        _createElementVNode("li", null, [
          _createVNode(_component_router_link, {
            to: { name: 'AppAdministratorInvoicesOutgoingInvoicesIndexVue' },
            class: _normalizeClass({ 'router-link-exact-active': _ctx.exactRoute === 'outgoing-invoices' })
          }, {
            default: _withCtx(() => [
              _createTextVNode(_toDisplayString(_ctx.translation.outgoingInvoices), 1)
            ]),
            _: 1
          }, 8, ["class"])
        ]),
        _createElementVNode("li", null, [
          _createVNode(_component_router_link, {
            to: { name: 'AppAdministratorInvoicesIncomingInvoicesIndexVue' },
            class: _normalizeClass({ 'router-link-exact-active': _ctx.exactRoute === 'incoming-invoices' })
          }, {
            default: _withCtx(() => [
              _createTextVNode(_toDisplayString(_ctx.translation.incomingInvoices), 1)
            ]),
            _: 1
          }, 8, ["class"])
        ]),
        _createElementVNode("li", null, [
          _createVNode(_component_router_link, {
            to: { name: 'AppAdministratorInvoicesExcerptsRootIndexVue' },
            class: _normalizeClass({ 'router-link-exact-active': _ctx.exactRoute === 'excerpts' })
          }, {
            default: _withCtx(() => [
              _createTextVNode(_toDisplayString(_ctx.translation.excerpts), 1)
            ]),
            _: 1
          }, 8, ["class"])
        ]),
        _createElementVNode("li", null, [
          _createVNode(_component_router_link, {
            to: { name: 'AppAdministratorInvoicesInvoicePaymentsIndexVue' },
            class: _normalizeClass({ 'router-link-exact-active': _ctx.exactRoute === 'invoice-payments' })
          }, {
            default: _withCtx(() => [
              _createTextVNode(_toDisplayString(_ctx.translation.invoicePayments), 1)
            ]),
            _: 1
          }, 8, ["class"])
        ]),
        _createElementVNode("li", null, [
          _createVNode(_component_router_link, {
            to: { name: 'AppAdministratorInvoicesCompensationsIndexVue' },
            class: _normalizeClass({ 'router-link-exact-active': _ctx.exactRoute === 'compensations' })
          }, {
            default: _withCtx(() => [
              _createTextVNode(_toDisplayString(_ctx.translation.compensations), 1)
            ]),
            _: 1
          }, 8, ["class"])
        ])
      ])
    ])
  ]))
}