
// Vue
import {Options, Vue} from "vue-class-component";

// Store
import store from "@/store";

// Models
import {AppOutgoingInvoiceIndex} from "@/models/app/outgoing-invoice";
import {AppOutgoingInvoiceCreateIndex} from "@/models/app/outgoing-invoice/create";
import {ResponseIndex} from "@/models/response";
import {PaginationIndex} from "@/models/pagination";

// Components
import {
  ElDialog,
  ElRow,
  ElCol,
  ElForm,
  ElFormItem,
  ElInput,
  ElDatePicker,
  ElSelect,
  ElOption,
  ElButton,
  ElAlert,
} from "element-plus";
import {
  Plus,
} from "@element-plus/icons-vue";

// Services
import {getRequest} from "@/services/api/request";
import {postRequest} from "@/services/api/request";
import {getTranslation} from "@/services/app/translation";

@Options({
  props: [
    "partners",
    "searchQuery",
    "partnerId",
    "status",
    "year",
    "pagination",
  ],
  components: {
    ElDialog,
    ElRow,
    ElCol,
    ElForm,
    ElFormItem,
    ElInput,
    ElDatePicker,
    ElSelect,
    ElOption,
    ElButton,
    ElAlert,
    Plus,
  },
  watch: {
    "formData.content.amount.eur"(): void {
      this.formData.content.amount.hrk = Number((this.formData.content.amount.eur) * 7.53450).toFixed(2);
    },
  },
})
export default class AppAdministratorInvoicesOutgoingInvoicesListFormCreateIndexVue extends Vue {
  isLoading = false;
  isDialogVisible = false;
  isDisabled = false;

  searchQuery = "*";
  partnerId = "*";
  status = "*";
  year = new Date().getFullYear();
  pagination = new PaginationIndex();

  formData: AppOutgoingInvoiceCreateIndex = new AppOutgoingInvoiceCreateIndex();
  formDataRules = {
    partner_id: [
      {
        required: true,
        trigger: "blur",
      },
    ],
    content: {
      year: [
        {
          required: true,
          trigger: "blur",
        },
      ],
      invoiceNumber: [
        {
          required: true,
          trigger: "blur",
        },
      ],
      invoiceNumberInYear: [
        {
          required: true,
          trigger: "blur",
        },
      ],
      paymentDescription: [
        {
          required: true,
          trigger: "blur",
        },
      ],
      amount: {
        hrk: [
          {
            required: true,
            trigger: "blur",
          },
        ],
        eur: [
          {
            required: true,
            trigger: "blur",
          },
        ],
      },
      date: [
        {
          required: true,
          trigger: "blur",
        },
      ],
      dateOfExchangeRate: [
        {
          required: true,
          trigger: "blur",
        },
      ],
    },
  };

  outgoingInvoiceLastInYear: AppOutgoingInvoiceIndex = new AppOutgoingInvoiceIndex();

  get translation(): any {
    return getTranslation([
      "add",
      "amountWithVAT",
      "choose",
      "date",
      "dateOfExchangeRate",
      "invoiceNumber",
      "invoiceNumberInYear",
      "partner",
      "paymentDescription",
      "titlePlaceholderAmountWithVAT",
      "titlePlaceholderDate",
      "titlePlaceholderDateOfExchangeRate",
      "titlePlaceholderInvoiceNumber",
      "titlePlaceholderInvoiceNumberInYear",
      "titlePlaceholderPaymentDescription",
      "titlePlaceholderYear",
      "year",
    ]);
  }

  $refs!: {
    formComponent: HTMLFormElement;
  };

  validateForm(): void {
    this.$refs.formComponent.validate((response: any) => {
      if (response) {
        this.submitForm();
      }
    });
  }

  async submitForm(): Promise<void> {
    this.isLoading = true;
    await postRequest({
      uri: "/common/outgoing-invoice/create",
      formData: this.formData,
      isProtected: true,
      isSuccessNotificationVisible: true,
      isErrorNotificationVisible: true,
    }).then((r: ResponseIndex) => {
      if (r.status === "success") {
        this.$emit('getOutgoingInvoices', this.searchQuery, this.partnerId, this.status, this.year, this.pagination.current_page);
        this.$emit('getTransactionsUnallocatedFunds', this.formData.partner_id, "12983eff-907a-413d-9759-8b1f0b3c383b", r.data);
        this.isDialogVisible = false;
        this.resetFormData();
      }
    });
    this.isLoading = false;
  }

  async getOutgoingInvoiceStatus(): Promise<void> {
    await getRequest({
      uri: `/common/outgoing-invoice/status`,
      formData: {
        organization_id: this.formData.organization_id,
        partner_id: this.formData.partner_id,
        year: this.formData.content.year,
        invoiceNumber: this.formData.content.invoiceNumber,
        invoiceNumberInYear: this.formData.content.invoiceNumberInYear,
      },
      isProtected: true,
    }).then((r: ResponseIndex) => {
      this.isDisabled = r.data !== null;
    });
  }

  async getOutgoingInvoiceLastInYear(): Promise<void> {
    await getRequest({
      uri: `/common/outgoing-invoice/last-in-year`,
      formData: {
        organization_id: store.getters.getOrganizationMember.organization_id,
      },
      isProtected: true,
    }).then((r: ResponseIndex) => {
      if (r.data) {
        this.outgoingInvoiceLastInYear = r.data;
        this.formData.content.invoiceNumberInYear = (parseInt(this.outgoingInvoiceLastInYear.content.invoiceNumberInYear) + 1).toString();
      }
      else {
        this.formData.content.invoiceNumberInYear = "1";
      }
    });
  }

  async resetFormData(): Promise<void> {
    this.formData = new AppOutgoingInvoiceCreateIndex();
    await this.getOutgoingInvoiceLastInYear();
  }

  async created(): Promise<void> {
    await this.getOutgoingInvoiceLastInYear();
  }
}
